import { Facebook, Instagram } from "@mui/icons-material";
import { Palette } from "../palette";
import "./Footer.css";
import { Logo } from "../images/Logo";

export const Footer = () => {
  return (
    <div className="footer-container">
      <div style={{marginTop: 20}}>
        <h2>
          <Logo
            height="75px"
            width="75px"
            style={{
              marginBottom: "-50px",
              //marginLeft: "-15px",
            }}
          />
          Bliss Packs
        </h2>
      </div>
      <div className="footer-content">
        <div className="footer-content-left">
          <h2>Bliss Packs Inc.</h2>
          <p>123 Bliss Street</p>
          <p>Blissville, BL 12345</p>
          <a href="mailto:info@blisspacks.com">info@blisspacks.com</a>
        </div>
        <div className="footer-content-right">
          <h2>Follow Us</h2>
          <div className="social-media">
            <a
              href="https://www.instagram.com/blisspacks/"
              target="_blank"
              rel="noreferrer"
            >
              <Instagram
                sx={{
                  color: Palette.lightGrey,
                  height: "1.5em",
                  width: "1.5em",
                }}
              />
            </a>
            <a
              href="https://www.facebook.com/blisspacks/"
              target="_blank"
              rel="noreferrer"
            >
              <Facebook
                sx={{
                  color: Palette.lightGrey,
                  height: "1.5em",
                  width: "1.5em",
                }}
              />
            </a>
            {/*     <a href="https://twitter.com/blisspacks" target="_blank" rel="noreferrer">
                <img src={twitter} alt="twitter" />
                </a> */}
          </div>
        </div>
      </div>
      <div className="footer-bottom">{/* <p>© 2021 Bliss Packs</p> */}</div>
    </div>
  );
};
