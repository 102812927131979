import * as React from "react";
import { SVGProps } from "react";
import { Palette } from "../palette";
export const DrawnLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={392}
    height={8}
    fill="none"
    viewBox="0 0 392 8"
    {...props}
  >
    <path
      fill={props.fill || Palette.pastelPink}
      d="M.231 4.007c-1.305-1.04 3.333-.969 3.547-.973 8.181-.142 16.362-.331 24.541-.526 18.848-.447 37.697-.893 56.546-1.32C160.602-.528 236.178-.32 311.914 1.39c19.548.441 39.097.885 58.646 1.302 3.955.084 17.364-2.006 20.741.492 1.374 1.017-3.122.973-3.304.976-8.257.138-16.515.317-24.771.504-18.849.425-37.699.851-56.548 1.263-76.052 1.66-151.938 1.74-227.989-.056-19.415-.459-38.831-.914-58.248-1.354-3.665-.083-17.148 1.929-20.21-.511Z"
    />
  </svg>
);
