import React from "react";
import "./App.css";
import "./Shine.css";
import "./MobileApp.css";
import "./Animation.css";
import "./whats-included/WhatsIncluded.css";
import { TopBar } from "./TopBar";
import { Card } from "./Card";
import { TopBarBox } from "./top-bar-box/TopBarBox";
import { PackType } from "./PackType";
import { Palette } from "./palette";
import { MainContent, MainContentList } from "./main-content/MainContent";
import { MainContentCard } from "./main-content/MainContentCard";
import { PaymentCard } from "./payment-card/PaymentCard";
import { tiers } from "./payment-card/Tiers";
import { Footer } from "./footer/Footer";
import { HowItWorks } from "./how-it-works/HowItWorks";
import { useEffect, useState } from "react";
import HeroCarousel from "./hero/HeroCarousel";
import { WhatsIncludedSection } from "./whats-included/WhatsIncludedSection";
import { Swizzle } from "./images/Swizzle";
import { DrawnLine } from "./images/DrawnLine";

function App() {
  const [packType, setPackType] = React.useState<PackType>("blanc");

  const appClass = "app-" + packType;
  return (
    <div className={appClass + " gradient"}>
      <TopBar />
      {/* <HeroCarousel /> */}
      <div
        className="main-content main-content-first"
        style={{
          color:
            packType === "noir" ? Palette.lightBackground : Palette.darkGrey,
        }}
      >
        <CardGrid
          rows={4}
          columns={4}
          rowHeight={250}
          columnWidth={250}
          additionalClasses="main-card-grid"
        >
          {[1, 2, 3, 4, 5, 6, 7].map((i) => (
            <Card packType={packType} id={"main-" + i}>
              {!!MainContentList[i - 1] && (
                <MainContentCard
                  content={MainContentList[i - 1]}
                  packType={packType}
                />
              )}
            </Card>
          ))}
        </CardGrid>
      </div>
      <Swizzle className="swizzle-divider" />
      <div
        className="main-content"
        style={{
          color:
            packType === "noir" ? Palette.lightBackground : Palette.darkGrey,
        }}
      >
        <WhatsIncludedSection />
      </div>
      <DrawnLine className="swizzle-divider" />
      <div
        className="main-content"
        style={{
          color:
            packType === "noir" ? Palette.lightBackground : Palette.darkGrey,
        }}
      >
        <HowItWorks />
      </div>

      <Swizzle className="swizzle-divider" />
      <div
        className="main-content"
        style={{
          color:
            packType === "noir" ? Palette.lightBackground : Palette.darkGrey,
        }}
      >
        <div id="order" className="payment-area-header">
          <h2
            className={`emphasised-underline-${packType} emphasised-underline-order`}
          >
            Available Now
          </h2>
        </div>
        <CardGrid
          rows={3}
          columns={3}
          rowHeight={250}
          columnWidth={250}
          additionalClasses="price-card-grid"
        >
          <Card packType={packType} id="price">
            <PaymentCard packType={"blanc"} tier={tiers[0]} />
          </Card>
          <Card packType={packType} id="price">
            <PaymentCard packType={"blanc"} tier={tiers[1]} />
          </Card>
          <Card packType={packType} id="price">
            <PaymentCard packType={packType} tier={tiers[2]} />
          </Card>
        </CardGrid>
      </div>
      <Footer />
    </div>
  );
}

const CardGrid: React.FC<{
  children: React.ReactNode;
  rows: number;
  columns: number;
  rowHeight: number;
  columnWidth: number;
  additionalClasses?: string;
}> = ({ children, additionalClasses }) => {
  return (
    <div className={"card-grid " + additionalClasses} style={{}}>
      {children}
    </div>
  );
};

export default App;
