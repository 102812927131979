export class Palette {
    // Noir Theme
    static readonly gold: string = '#937316';
    static readonly darkGrey: string = '#333333';
    static readonly lightGrey: string = '#CCCCCC';
    static readonly black: string = '#000000';

    // Blanc Theme
    static readonly pastelPink: string = '#FFB6C1';
    static readonly pastelPurple: string = '#E6E6FA';
    static readonly lightBackground: string = '#FFFFFF';
    static readonly paleGrey: string = '#F5F5F5';

    static readonly softBlue: string = '#87CEEB';
    static readonly pastelGreen: string = '#77DD77';

    static readonly softPink: string = '#FFB6C1';
    static readonly softPurple: string = '#E6E6FA';

    static readonly primary: string = this.softPink;
    static readonly secondary: string = this.softPurple;

}

