
import React from 'react';
import './NavBar.css';

const NavBar: React.FC = () => {
    return (
        <nav className='nav-bar'>
            <ul>
                <li>
                    <a href="#whats-included">Included</a>
                </li>
                <li>
                    <a href="#how-it-works">How it works</a>
                </li>
              
            </ul>
        </nav>
    );
};

export default NavBar;