export class Tier {
  public name: string;
  public price: number;
  public description: string;
  public level: number;
  public includedItems: string[];
  public recommended: boolean;
  public isMonthly: boolean;
}

export const tiers: Tier[] = [
  {
    name: "Tranquility",
    price: 14.99,
    description: "A great pack for beginners",
    level: 1,
    includedItems: [
      "Mindfulness activities",
      "Mindful eating",
      "Mindful walking",
    ],
    recommended: false,
    isMonthly: false,
  },
  {
    name: "Serenity",
    price: 24.99,
    description: "A great pack for intermediate users",
    level: 2,
    includedItems: [
      "Mindfulness activities",
      "Mindful eating",
      "Mindful walking",
      "Mindful breathing",
    ],
    recommended: false,
    isMonthly: false,
  },
  {
    name: "Zenith",
    price: 9.99,
    description: "A great pack for advanced users",
    level: 3,
    includedItems: [
      "Mindfulness activities",
      "Mindful eating",
      "Mindful walking",
      "Mindful breathing",
      "Mindful yoga",
    ],
    recommended: true,
    isMonthly: true,
  },
];
