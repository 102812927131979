import "./PaymentCard.css";
import { Tier } from "./Tiers";
export const PaymentCard: React.FC<{
  packType: "noir" | "blanc";
  tier: Tier;
}> = ({ packType, tier }) => {
  return (
    <div
      className="payment-card-container"

    >
      <div className="payment-card-background"></div>
      <div
        className={
          "payment-card-foreground" +
          (tier.recommended
            ? " payment-card-foreground-"+packType
            : "")
        }
      >
        <div
          className={"recommended " + packType}
          style={{ visibility: !!tier.recommended ? "visible" : "hidden" }}
        >
          Recommended
        </div>
        <div className="tier-title">
          {tier.name} {" "}
          pack
        </div>
        <div className="tier-description">
          {tier.description}
        </div>
        <div className="price">
          <span className="symbol">£</span>
          <span className="cost">{tier.price}</span>
          {tier.isMonthly && <span className="monthly">per month</span>}
        </div>
        <div className="included">
          <p>Included</p>
        </div>
      </div>
    </div>
  );
};
