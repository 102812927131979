import React from 'react';
import IncludedItem from './IncludedItem';
import {BubbleChart, HeartBroken, HistoryEdu, LocalFlorist, NaturePeople, Send, Spa, Style} from '@mui/icons-material';



export const WhatsIncludedSection: React.FC = () => {
    return (
        <div className='whats-included-container' id='whats-included'>
            <div className='whats-included-header'>
            <h2>Whats Included</h2>
            </div>
            <div className='whats-included-grid'>
                <IncludedItem logo={Style} text="Daily cards with an blissbreak activity designed to reconnect you with your inner child" />
                <IncludedItem logo={Send} text="5 minute crafts including paper aeroplanes and origami" />
                <IncludedItem logo={BubbleChart} text="Calm-inducing experiences like blowing bubbles and coloring" />
                <IncludedItem logo={HistoryEdu} text="Introspective and inspiring poems" />
                <IncludedItem logo={Spa} text="Pamper your self-assurance with soothing beauty essentials." />
                <IncludedItem logo={NaturePeople} text="Each included item is chosen to be as environmentall friendly as possible" />
                
            </div>
        </div>
    );
};
