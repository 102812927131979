import { BuyNow } from "./BuyNow";
import { Logo } from "./images/Logo";
import NavBar from "./nav-bar/NavBar";
import { Palette } from "./palette";
import { useState, useEffect } from "react";

const minPosition = 36;

export const TopBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > minPosition) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setIsScrolled]);
  return (
    <div
      className={
        "top-bar-layout-container " +
        (isScrolled ? "top-bar-layout-container-min" : "")
      }
    >
      <div
        className={"top-bar-layout" + (isScrolled ? " top-bar-layout-min" : "")}
      >
        <Logo
          onClick={() => window.scrollTo(0, 0)}
          className={"top-bar-logo " + (isScrolled ? " top-bar-logo-min" : "")}
          fill1={Palette.primary}
          fill2={Palette.secondary} // Add this line to align the SVG with other items
        />

        <h1
          className={
            "text-with-gradient" + (isScrolled ? " text-with-gradient-min" : "")
          }
          style={{
            cursor: "pointer",
          }}
          onClick={() => window.scrollTo(0, 0)}
        >
          <span
            className={
              "emphasised top-bar-emphasised " +
              (isScrolled ? "top-bar-emphasised" : "")
            }
          >
            Bliss
          </span>
          Breaks
        </h1>

        <NavBar />
        <BuyNow />
      </div>
    </div>
  );
};
