import React, { SVGProps } from "react";
import { Palette } from "../palette";

interface IncludedItemProps {
  logo: React.ElementType;
  text: string;
}

const IncludedItem: React.FC<IncludedItemProps> = ({ logo: Logo, text }) => {
  return (
    <div className="whats-included-item">
      <Logo style={{ height: 50, width: 50, fill:Palette.primary }} />
      <p>{text}</p>
    </div>
  );
};

export default IncludedItem;
