import { MainAdditional } from "./MainAdditional";
import { PackType } from "../PackType";

export class MainContent {
  title?: string;
  textSize?: "small" | "medium" | "large" = "medium";
  body?: string;
  imageStyles?: React.CSSProperties;
  imageLink?: string;
  videoLink?: string;
  additionalClasses?: string;
  additionalComponent?: React.ComponentType<{ packType: PackType }>;
}

export const MainContentList: MainContent[] = [
  //1
  {
    title: "You deserve a BlissBreak.",
    textSize: "large",
    body: "Discover *tranquility* with BlissBreaks, daily mindful activities to *[u]Reconnect* with your inner child through small moments of peace and joy each day, while *nurturing* a deeper connection with your surrounding environment through sustainable and ecologically minded practices.",
    additionalComponent: MainAdditional,
  },
  //2
  {
    title: "",
    body: "",
    imageLink: "./sc1.jpg",
    imageStyles: {
      backgroundSize: "160%",
      backgroundPosition: "50% 65%",
    },
    videoLink: "./bb-2.mp4",
  },

  //3
  {
    title: "",
    body: "",
    imageLink: "./beautybox_top.jpg",
  },
  //4
  {
    title: "",
    textSize: "small",
    body: "",
    additionalClasses: "line",
    videoLink: "./bb-1.mp4",
  },
  //5
  {
    title: "",
    body: "",
    imageLink: "./sc1.jpg",
    imageStyles: {
      backgroundSize: "160%",
      backgroundPosition: "50% 65%",
    },
    videoLink: "./bb-3.mp4",
  },
  //6
  {
    title: "",
    body: "",
    imageLink: "./bb_1.jpg",
    imageStyles: {
      backgroundSize: "100%",
      backgroundPosition: "50% 61%",
    },
  },
  //7
  {
    title: "",
    body: "",
    imageLink: "./sc1.jpg",
    imageStyles: {
      backgroundSize: "160%",
      backgroundPosition: "50% 65%",
    },
  },
];
