import * as React from "react";
import { Palette } from "../palette";

export const Swizzle = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 661 99"
    width={661}
    height={99}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill || Palette.pastelPink}
      d="M.372 31.575c-2.347.487 7.14-3.174 8.333-3.489 6.136-1.618 12.266-3.26 18.4-4.882C46.064 18.19 65.091 13.42 84.352 9.685 139.012-.917 240.086-5.234 273.438 51.246c29.949 50.717-113.109 74.156-90.383 3.143 2.441-7.626 6.72-14.592 11.869-20.694 7.119-8.438 16.171-15.116 25.898-20.284 68.367-36.316 136.764 10.772 203.135 27.828 80.457 20.678 161.645 11.759 236.682-23.643 2.292-1.08-7.036 4.828-8.258 5.395-7.136 3.311-14.338 6.486-21.657 9.38-22.463 8.878-45.734 15.858-69.569 19.972-71.717 12.379-140.452-2.636-208.359-25.21-44.5-14.791-88.996-28.482-133.096-3.901-17.13 9.548-46.525 39.598-27.979 60.603 16.889 19.128 86.658 8.23 78.921-22.64-6.038-24.091-43.145-37.616-63.966-43.391C139.111-.938 66.996 17.762.372 31.575Z"
    />
  </svg>
);
