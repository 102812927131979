import { PackType } from "../PackType";
import { MainContent } from "./MainContent";

export const MainContentCard: React.FC<{
  content: MainContent;
  packType: PackType;
}> = ({ content, packType }) => {
  if (content.textSize === undefined) content.textSize = "medium";
  //I need to deterime if the content has asterics, if so, I need to split the string and add a span around the word
  let contents = content.body.split("*");
  if (content.videoLink) {
    return (
      <video
        autoPlay
        loop
        muted
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "6px",
        }}
      >
        <source src={content.videoLink} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  }
  return (
    <div
      className="main-content-card-container"
      style={{
        backgroundImage: `url(${content.imageLink})`,
        padding: "12px",
        height: "calc(100% - 24px)",
        width: "calc(100% - 24px)",
        borderRadius: "6px",
        backgroundSize: "150% 150%",
        backgroundPosition: "center center",
        ...(content.imageStyles || {}),
      }}
    >
      {content.title.length || content.body.length ? (
        <div
          className={"main-content-card " + content.additionalClasses}
          style={{ overflow: "hidden", height: "100%", width: "100%" }}
        >
          {content.textSize === "large" && <h1>{content.title}</h1>}
          {content.textSize === "medium" && <h2>{content.title}</h2>}
          {content.textSize === "small" && <h3>{content.title}</h3>}
          {contents.map((content, index) => {
            if (index % 2 === 1) {
              return (
                <span
                  className={
                    "emphasised" +
                    (content.startsWith("[u]")
                      ? " emphasised-underline-" + packType
                      : "")
                  }
                  key={index}
                >
                  {content.replace("[u]", "")}
                </span>
              );
            }
            return content;
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
