import React from "react";
import ShoppingBasket from "./images/ShoppingBasket";
import { Palette } from "./palette";

export const BuyNow: React.FC = () => {
  return <a href="#order" className="buy-now-btn"><ShoppingBasket
  className='text-with-gradient'
  fill={Palette.primary}
  height={40} width={40}
  /></a>;
};
