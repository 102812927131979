import {
  AddShoppingCart,
  LocalShipping,
  Schedule,
  ShoppingBagOutlined,
} from "@mui/icons-material";
import React from "react";
import "./HowItWorks.css";
import { Palette } from "../palette";

interface HowItWorksStepProps {
  stepNumber: number;
  description: string;
}

interface HowItWorksStepProps {
  stepNumber: number;
  description: string;
  Icon: React.ComponentType<any>;
}

const HowItWorksStep: React.FC<HowItWorksStepProps> = ({
  stepNumber,
  description,
  Icon,
}) => (
  <div className="how-it-works-step">
    <Icon
      style={{
        fontSize: 50,
        fill: Palette.primary,
      }}
    />

    <p>{description}</p>
  </div>
);

export const HowItWorks: React.FC = () => {
  return (
    <div className="how-it-works" id='how-it-works'>
      <h1 className="emphasised">How it works</h1>
      <div className="how-it-works-steps">
        <HowItWorksStep
          stepNumber={1}
          description="Choose a BlissBreak box"
          Icon={ShoppingBagOutlined}
        />
        <HowItWorksStep
          stepNumber={2}
          description="Receive the box in the post"
          Icon={LocalShipping}
        />
        <HowItWorksStep
          stepNumber={3}
          description="If you chose the re-occurring order, we'll send you another one after 30 days"
          Icon={Schedule}
        />
      </div>
    </div>
  );
};
