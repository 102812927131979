export const Card: React.FC<{
  packType: "noir" | "blanc";
  id: number | string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}> = ({ packType, children, style, id }) => {
  return (
    <div
      className={
        // eslint-disable-next-line
        (packType === "noir" ? "noir" : "blanc") +
        // eslint-disable-next-line
        "-gradient" +
        // eslint-disable-next-line
        " card" +
        " card-" +
        id
      }
      style={{
        height: "100%",
        width: "100%",
        ...(style ?? {}),
      }}
    >
      {children}
    </div>
  );
};
